import React, { useMemo, useState, useEffect } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SimpleLineChart = (props) => {
  const { chartData } = props;

  // Estados para los filtros
  const [selectedDevice, setSelectedDevice] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // Función para obtener los últimos N datos (ya vienen ordenados)
  const getLatestData = (data, limit = 20) => {
    return data.slice(0, limit);
  };

  // Inicializamos filteredData con los últimos 20 datos
  const [filteredData, setFilteredData] = useState(() =>
    getLatestData(chartData)
  );

  // Actualizamos los datos cuando cambia chartData
  useEffect(() => {
    setFilteredData(getLatestData(chartData));
  }, [chartData]);

  // Función para generar colores HSL aleatorios pero visualmente distintos
  const generateColor = (index, total) => {
    // Usamos HSL para mejor control sobre el tono
    const hue = (index * (360 / total)) % 360; // Distribuye los colores uniformemente
    const saturation = 70; // Mantenemos saturación constante
    const lightness = 60; // Mantenemos brillo constante
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  // Detectamos automáticamente los dispositivos únicos
  const devices = useMemo(() => {
    if (!chartData.length) return [];
    return [...new Set(chartData.map((item) => item.device))];
  }, [chartData]);

  // Generamos un mapa de colores para cada dispositivo
  const deviceColors = useMemo(() => {
    return devices.reduce((acc, device, index) => {
      acc[device] = generateColor(index, devices.length);
      return acc;
    }, {});
  }, [devices]);

  // Manejador para cambios en el rango de fechas
  const handleDateRangeChange = (date, field) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  // Función para filtrar los datos
  const handleFilter = () => {
    let results = [...chartData];

    if (selectedDevice) {
      results = results.filter((row) => row.device === selectedDevice);
    }

    if (dateRange.startDate || dateRange.endDate) {
      results = results.filter((row) => {
        if (!row.f) return false;
        const rowDate = new Date(row.f);
        const startDateTime = dateRange.startDate
          ? new Date(dateRange.startDate)
          : null;
        const endDateTime = dateRange.endDate
          ? new Date(dateRange.endDate)
          : null;

        if (startDateTime && endDateTime) {
          return rowDate >= startDateTime && rowDate <= endDateTime;
        } else if (startDateTime) {
          return rowDate >= startDateTime;
        } else if (endDateTime) {
          return rowDate <= endDateTime;
        }
        return true;
      });
    } else {
      // Si no hay fechas seleccionadas, mostramos los últimos 20
      results = getLatestData(results);
    }

    setFilteredData(results);
  };

  return (
    <div className="flex h-full w-full flex-col p-6">
      {/* Filtros */}
      <div className="mb-4 flex flex-wrap items-center gap-3">
        <div className="flex items-center gap-2">
          <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
            Dispositivo:
          </label>
          <select
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[180px] xl:w-[180px] 2xl:w-[220px]"
          >
            <option value="">Todos</option>
            {devices.map((device) => (
              <option key={device} value={device}>
                {device}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center gap-2">
          <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
            Desde:
          </label>
          <DatePicker
            selected={dateRange.startDate}
            onChange={(date) => handleDateRangeChange(date, "startDate")}
            showTimeInput
            timeInputLabel="Hora:"
            dateFormat="dd/MM/yyyy HH:mm"
            placeholderText="Fecha inicial"
            className="w-[110px] rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[120px] xl:w-[125px] 2xl:w-[160px]"
          />
        </div>

        <div className="flex items-center gap-2">
          <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
            Hasta:
          </label>
          <DatePicker
            selected={dateRange.endDate}
            onChange={(date) => handleDateRangeChange(date, "endDate")}
            showTimeInput
            timeInputLabel="Hora:"
            dateFormat="dd/MM/yyyy HH:mm"
            placeholderText="Fecha final"
            className="w-[110px] rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[120px] xl:w-[125px] 2xl:w-[160px]"
          />
        </div>

        <button
          onClick={handleFilter}
          className="rounded-lg bg-blue-500 px-3 py-2 text-sm font-medium text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 xl:px-4"
        >
          Filtrar
        </button>
      </div>

      {/* Gráfico */}
      <div className="flex-1">
        {filteredData.length === 0 ? (
          <div className="flex h-full w-full items-center justify-center">
            <div className="rounded-lg bg-gray-50 p-8 text-center dark:bg-navy-700">
              <p className="text-lg font-medium text-gray-600 dark:text-gray-300">
                No hay datos disponibles
              </p>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {selectedDevice || dateRange.startDate || dateRange.endDate
                  ? "Prueba ajustando los filtros para ver más resultados"
                  : "No hay datos para mostrar en este momento"}
              </p>
            </div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={filteredData}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
                left: 30,
              }}
            >
              <CartesianGrid strokeDasharray="4 1 2" />
              <XAxis
                dataKey="f"
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return `${date.getHours()}:${date.getMinutes()}`;
                }}
              />
              <YAxis domain={["auto", "auto"]} />
              <Tooltip
                labelFormatter={(value) => {
                  const date = new Date(value);
                  return `${date.toLocaleString()}`;
                }}
                formatter={(value, name, props) => {
                  return [`Sensor ${props.payload.device}: ${value}`];
                }}
              />
              <Legend />
              {(selectedDevice ? [selectedDevice] : devices).map((device) => (
                <Line
                  key={device}
                  type="monotone"
                  dataKey="q"
                  data={filteredData.filter((item) => item.device === device)}
                  stroke={deviceColors[device]}
                  name={`Sensor ${device}`}
                  connectNulls
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default SimpleLineChart;
