import Card from "../card";
import React, { useState, useMemo, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { utils as xlsxUtils, writeFile } from "xlsx";
import { FiDownload } from "react-icons/fi";

const DataTableFields = (props) => {
  const { columnsData, tableData } = props;
  const [filterInput, setFilterInput] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [filteredResults, setFilteredResults] = useState([...tableData]);
  const [selectedDevice, setSelectedDevice] = useState("");

  const columns = useMemo(() => columnsData, [columnsData]);
  const initialState = React.useMemo(
    () => props.initialState,
    [props.initialState]
  );

  const deviceOptions = useMemo(() => {
    const devices = tableData
      .map((row) => row.device)
      .filter(
        (device, index, self) => device && self.indexOf(device) === index
      );
    return ["", ...devices];
  }, [tableData]);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterInput(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  const handleFilter = () => {
    let results = [...tableData];

    if (selectedDevice) {
      results = results.filter((row) => row.device === selectedDevice);
    }

    if (filterInput.trim()) {
      const searchLower = filterInput.toLowerCase();
      results = results.filter((row) =>
        Object.values(row).some((val) =>
          val?.toString().toLowerCase().includes(searchLower)
        )
      );
    }

    if (dateRange.startDate || dateRange.endDate) {
      results = results.filter((row) => {
        if (!row.f) return false;

        const [datePart, timePart] = row.f.split(" ");
        const [day, month, year] = datePart.split("/");
        const [hours, minutes] = timePart ? timePart.split(":") : [0, 0];
        const rowDate = new Date(year, month - 1, day, hours, minutes);

        const startDateTime = dateRange.startDate
          ? new Date(dateRange.startDate)
          : null;
        const endDateTime = dateRange.endDate
          ? new Date(dateRange.endDate)
          : null;

        if (startDateTime && endDateTime) {
          return rowDate >= startDateTime && rowDate <= endDateTime;
        } else if (startDateTime) {
          return rowDate >= startDateTime;
        } else if (endDateTime) {
          return rowDate <= endDateTime;
        }
        return true;
      });
    }

    setFilteredResults(results);
    const validPageSize = Math.max(1, results.length);
    setPageSize(validPageSize);
    setGlobalFilter("");
  };

  useEffect(() => {
    setFilteredResults(tableData);
  }, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data: filteredResults,
      initialState: {
        ...initialState,
        pageSize: 20,
      },
      manualPagination: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handleDateRangeChange = (date, field) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handlePageSizeChange = (e) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
  };

  const handleExportToExcel = () => {
    // Create worksheet from filtered data
    const ws = xlsxUtils.json_to_sheet(
      filteredResults.map((row) => {
        const formattedRow = {};
        columns.forEach((col) => {
          formattedRow[col.Header] = row[col.accessor];
        });
        return formattedRow;
      })
    );

    const wb = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(wb, ws, "Datos");

    writeFile(wb, "datos_dispositivos.xlsx");
  };

  const pagClass =
    "p-[10px] x-4 text-sm font-medium text-gray-900 bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="mb-4 flex flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center lg:gap-3 xl:flex-row xl:items-center xl:justify-start xl:gap-6 2xl:gap-6">
        {/* Contenedor de búsqueda y dispositivo */}
        <div className="flex w-full flex-col gap-3 sm:flex-row sm:items-center lg:w-auto lg:flex-nowrap xl:gap-5">
          <div className="flex items-center gap-2">
            <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
              Buscar:
            </label>
            <input
              value={filterInput}
              onChange={handleFilterChange}
              onKeyPress={handleKeyPress}
              placeholder="Buscar..."
              className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 placeholder-gray-500 dark:border-gray-600 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400 sm:w-64 lg:w-[220px] xl:w-[200px] 2xl:w-[260px]"
            />
          </div>

          {deviceOptions.length > 1 && (
            <div className="flex items-center gap-2">
              <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
                Dispositivo:
              </label>
              <select
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(e.target.value)}
                className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[180px] xl:w-[180px] 2xl:w-[220px]"
              >
                <option value="">Todos</option>
                {deviceOptions.map(
                  (device, index) =>
                    device && (
                      <option key={index} value={device}>
                        {device}
                      </option>
                    )
                )}
              </select>
            </div>
          )}
        </div>

        {/* Contenedor de fechas y botones */}
        <div className="flex w-full flex-wrap gap-3 lg:w-auto lg:flex-nowrap lg:items-center xl:gap-5">
          <div className="flex flex-wrap items-center gap-3 lg:flex-nowrap xl:gap-5">
            <div className="flex items-center gap-2">
              <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
                Desde:
              </label>
              <DatePicker
                selected={dateRange.startDate}
                onChange={(date) => handleDateRangeChange(date, "startDate")}
                showTimeInput
                timeInputLabel="Hora:"
                dateFormat="dd/MM/yyyy HH:mm"
                placeholderText="Fecha inicial"
                className="w-[110px] rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[120px] xl:w-[125px] 2xl:w-[160px]"
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="whitespace-nowrap text-sm font-medium text-gray-700 dark:text-white">
                Hasta:
              </label>
              <DatePicker
                selected={dateRange.endDate}
                onChange={(date) => handleDateRangeChange(date, "endDate")}
                showTimeInput
                timeInputLabel="Hora:"
                dateFormat="dd/MM/yyyy HH:mm"
                placeholderText="Fecha final"
                className="w-[110px] rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 dark:border-gray-600 dark:bg-navy-700 dark:text-white lg:w-[120px] xl:w-[125px] 2xl:w-[160px]"
              />
            </div>
            <div className="flex gap-2 lg:ml-2 xl:ml-4">
              <button
                onClick={handleFilter}
                className="rounded-lg bg-blue-500 px-3 py-2 text-sm font-medium text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 xl:px-4"
              >
                Filtrar
              </button>
              <button
                onClick={handleExportToExcel}
                className="rounded-lg bg-green-500 px-3 py-2 text-sm font-medium text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 xl:px-4"
              >
                <div className="flex items-center justify-center gap-1 xl:gap-2">
                  <FiDownload />
                  Descargar
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="text-navy-700 dark:text-white">
        <table {...getTableProps()} className="divide-y divide-gray-200">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-6 py-3 text-left text-xs font-normal capitalize tracking-wider text-gray-500"
                    style={{ width: "200px" }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="hover:bg-indigo-50 dark:hover:bg-indigo-900"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className="px-[10px] pb-[20px] pt-[14px] font-normal sm:text-[14px]"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="relative mt-7 flex items-center justify-between">
          <div className="flex flex-1 justify-center">
            <div className="flex rounded-md shadow-sm" role="group">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className={pagClass + " rounded-s-lg border"}
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className={pagClass + " border-b border-r border-t"}
              >
                {"<"}
              </button>{" "}
              <span className="x-4 border-b border-t border-gray-200 bg-white p-[10.5px] text-sm font-medium text-gray-900 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:focus:text-white dark:focus:ring-blue-500">
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{" "}
              </span>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={pagClass + " border-b border-l border-t"}
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className={pagClass + " rounded-e-lg border"}
              >
                {">>"}
              </button>
            </div>
          </div>
          <select
            value={pageSize}
            className="mb-6 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            onChange={handlePageSizeChange}
          >
            {[5, 10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                {size} por página
              </option>
            ))}
          </select>
        </div>
      </div>
    </Card>
  );
};

export default DataTableFields;
