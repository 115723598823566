import { useState, useEffect } from "react";
import axios from "axios";
import SimpleLineChart from "../../../components/charts/SimpleLineChart";
import GaugeChart from "../../../components/charts/GaugeChart";
const MainDashboard = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.post("/web/devices/data", {});
        if (response.data.success) {
          const formattedData = response.data.rows.reduce((acc, row) => {
            const device = row.device;
            if (!acc[device]) {
              acc[device] = [];
            }
            acc[device].push(row);
            return acc;
          }, {});

          const combinedData = Object.values(formattedData)
            .map((deviceData) =>
              deviceData.slice(-20).map((row) => ({
                ...row,
                f: new Date(row.f).toISOString(),
              }))
            )
            .flat()
            .sort((a, b) => new Date(a.f) - new Date(b.f));

          setChartData(combinedData);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();

    const interval = setInterval(loadData, 30000);

    // Limpieza al desmontar
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mt-3">
      <h1 className="dark:text-white">Dashboard</h1>
      <div className="grid gap-4">
        <div className="h-[400px] w-full">
          <SimpleLineChart chartData={chartData} />
        </div>
        <div className="h-[300px] w-full">
          <GaugeChart data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
