import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import SwitchField from "components/fields/SwitchField";
import { useBetween } from "use-between";
import _loading from "_loading";
import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";
import NumberField from "components/fields/NumberField";
import DataTableFields from "components/table/DataTableFields";
import { useLocation } from "react-router-dom";
const Data = () => {
  const [data, setData] = useState([]);
  const { isOpen, onClose } = useDisclosure();
  const [modalTitle] = useState("Nuevo Dispositivo");
  const [activeRow, setActiveRow] = useState({});
  const [error] = useState("");
  const [organizations] = useState([]);
  const [modelos] = useState([]);
  const es = require("../../../components/i18n/es.json");
  const [setSelectedModel] = useState("");
  const { state } = useLocation();

  const [columns, setColumns] = useState([]);

  const { setLoading } = useBetween(_loading);

  const loadData = async () => {
    setLoading(true);
    const response = await axios.post("/web/devices/data", {
      device_id: (state || {}).device_id,
    });
    if (response.data.success) {
      setColumns(
        response.data.cols.map((col) => ({
          Header: col.n,
          accessor: col.i,
        }))
      );

      const data = response.data.rows;
      setData(data);
      setLoading(false);
    }
    //console.log(data);
  };

  const handleModelChange = (model) => {
    setSelectedModel(model);
  };

  useEffect(() => {
    //console.log("useEffect");
    loadData();
  }, []);

  const drawFields = (fields) => {
    var widgets = [];
    widgets = fields.map((field, index) => {
      if (field.tipo === "texto") {
        //activeRow.config[field.id];
        return (
          <InputField
            key={field.id}
            label={field.nombre}
            id={field.id}
            name={field.id}
            value={activeRow.config[field.id]}
            defaultValue={activeRow.config[field.id] || field.por_defecto}
            required={field.requerido}
            extra="h-[90px]"
            onChange={(v) => {
              activeRow.config[field.id] = v.target.value;
              setActiveRow({ ...activeRow });
            }}
          />
        );
      }
      if (field.tipo === "numerico") {
        activeRow.config[field.id] ??= field.por_defecto;
        return (
          <NumberField
            key={index}
            label={field.nombre}
            id={field.id}
            value={activeRow.config[field.id] || field.por_defecto}
            extra="h-[90px]"
            required={field.requerido}
            onChange={(v) => {
              activeRow.config[field.id] = v.target.value;
              setActiveRow({ ...activeRow });
            }}
          />
        );
      }
      if (field.tipo === "selector") {
        if (typeof activeRow.config[field.id] === "string") {
          activeRow.config[field.id] = field.opciones.find(
            (opt) => opt.valor === activeRow.config[field.id]
          );
          setActiveRow({ ...activeRow });
        }

        return (
          <SelectField
            key={index}
            label={field.nombre}
            id={field.id}
            value={(activeRow.config[field.id] || {}).valor}
            extra="h-[90px]"
            required={field.requerido}
            options={field.opciones.map((opt) => ({
              value: opt.valor,
              label: opt.titulo,
            }))}
            onChange={(v) => {
              //console.log("Change!!!!");
              activeRow.config[field.id] = field.opciones.find(
                (opt) => opt.valor === v.target.value
              );
              setActiveRow({ ...activeRow });
            }}
          />
        );
      }
      if (field.tipo === "switch") {
        if (
          typeof activeRow.config[field.id] === "boolean" &&
          activeRow.config[field.id]
        ) {
          activeRow.config[field.id] = {
            checked: activeRow.config[field.id],
            campos: field.campos,
          };
          setActiveRow({ ...activeRow });
        }

        return (
          <SwitchField
            key={index}
            label={field.nombre}
            id={field.id}
            defaultValue={field.por_defecto}
            value={(activeRow.config[field.id] || {}).checked || false}
            extra="h-[90px]"
            required={field.requerido}
            onChange={(v) => {
              activeRow.config[field.id] ??= {};
              if (v.target.checked) {
                activeRow.config[field.id].checked = true;
                activeRow.config[field.id].campos = field.campos;
              } else {
                activeRow.config[field.id].checked = false;
                if (activeRow.config[field.id].campos) {
                  activeRow.config[field.id].campos.forEach((campo) => {
                    activeRow.config[campo.id] = null;
                  });
                }
                activeRow.config[field.id].campos = null;
              }
              setActiveRow({ ...activeRow });
            }}
          />
        );
      }
    });
    return widgets;
  };

  return (
    <>
      <div className="mt-3 dark:text-white">
        <DataTableFields columnsData={columns} tableData={data} title="" />
      </div>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="!z-[1010]"
        scrollBehavior={"outside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%]">
          <ModalBody>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="ml-[18px] flex h-[90px] w-[100%] flex-row items-center">
                <h2 className="text-2xl">{modalTitle}</h2>
              </div>

              <div className="mb-5 ml-5 mr-5 grid grid-cols-2 gap-4 rounded-lg">
                <div>
                  <InputField
                    label="Serial"
                    id="serial"
                    name="serial"
                    type="text"
                    required={true}
                    defaultValue={activeRow.serial}
                    onChange={(v) =>
                      setActiveRow({ ...activeRow, serial: v.target.value })
                    }
                  />
                </div>
                <div>
                  <InputField
                    label="Nombre"
                    id="nombre"
                    name="nombre"
                    type="text"
                    defaultValue={activeRow.nombre}
                    onChange={(v) =>
                      setActiveRow({ ...activeRow, nombre: v.target.value })
                    }
                  />
                </div>
                <div>
                  <SelectField
                    label="Modelo"
                    id="modelo"
                    options={modelos}
                    disabled={activeRow._id && activeRow.modelo}
                    value={(activeRow.modelo || {}).value}
                    onChange={(v) => {
                      setActiveRow({
                        ...activeRow,
                        modelo: modelos.find((m) => m.value === v.target.value),
                      });
                    }}
                  />
                </div>
                <div>
                  <SelectField
                    label="Organización"
                    id="grupo"
                    options={organizations}
                    value={activeRow.grupo}
                    onChange={(v) =>
                      setActiveRow({ ...activeRow, grupo: v.target.value })
                    }
                  />
                </div>
              </div>
              <hr className="mb-5" />
              <div className="mb-5 ml-5 mr-5 grid grid-cols-2 gap-4 rounded-lg">
                {activeRow.modelo &&
                  activeRow.modelo.fields &&
                  drawFields(activeRow.modelo.fields)}
                {activeRow.config &&
                  Object.keys(activeRow.config).map((key) => {
                    if ((activeRow.config[key] || {}).campos) {
                      return drawFields(activeRow.config[key].campos);
                    }
                  })}
              </div>

              {error && (
                <div className="m-5">
                  <Alert
                    icon={<AiFillExclamationCircle color="white" />}
                    status="error"
                    title="Error"
                    message={error}
                  />
                </div>
              )}

              <div className="mb-[20px] ml-[18px] mr-[20px] mt-[40px] grid grid-cols-2">
                <div>
                  <button
                    type="button"
                    onClick={onClose}
                    className="rounded-lg border-2 border-red-50 p-2 font-medium text-red-500 transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="text-end">
                  <button
                    type="submit"
                    className="rounded-lg bg-green-500 p-2 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Data;
