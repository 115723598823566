import React from "react";

import MainDashboard from "views/client/default";

import { MdHome } from "react-icons/md";
import Devices from "views/client/devices";
import { layout } from "@chakra-ui/react";
import Groups from "views/client/groups";
import { SlOrganization } from "react-icons/sl";
import { MdComputer } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import Data from "views/client/data";
import GlobalData from "views/client/global_data";

const routes = [
  {
    name: "Home",
    layout: "/client",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Organizaciones",
    layout: "/client",
    path: "groups",
    icon: <SlOrganization className="h-6 w-6" />,
    component: <Groups />,
  },
  {
    name: "Dispositivos",
    layout: "/client",
    path: "devices",
    icon: <MdComputer className="h-6 w-6" />,
    component: <Devices />,
  },
  {
    name: "Datos",
    layout: "/client",
    path: "data",
    icon: <FaDatabase className="h-6 w-6" />,
    hidden: true,
    component: <Data />,
  },
  {
    name: "Datos",
    layout: "/client",
    path: "data",
    icon: <FaDatabase className="h-5 w-6" />,
    component: <GlobalData />,
  },
];
export default routes;
