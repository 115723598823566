import React, { useMemo } from "react";
import { Card, Select } from "@chakra-ui/react";

const GaugeChart = ({ data, onDeviceChange }) => {
  const devices = useMemo(() => {
    if (!data.length) return [];
    return [...new Set(data.map((item) => item.device))];
  }, [data]);

  const [selectedDevice, setSelectedDevice] = React.useState(devices[0]);

  // Calculamos estadísticas para el dispositivo seleccionado
  const stats = useMemo(() => {
    if (!data.length) return { current: 0, min: 0, max: 0, avg: 0 };

    const deviceData = data.filter((item) => item.device === selectedDevice);
    const values = deviceData.map((item) => item.q);

    return {
      current: values[values.length - 1] || 0,
      min: Math.min(...values),
      max: Math.max(...values),
      avg: values.reduce((a, b) => a + b, 0) / values.length,
    };
  }, [data, selectedDevice]);

  // Función para convertir grados a radianes
  const toRadians = (angle) => (angle * Math.PI) / 180;

  // Función para calcular punto en el arco
  const getPointOnArc = (angle, customRadius = null) => {
    const radius = customRadius || 40;
    const x = 50 + radius * Math.cos(toRadians(angle - 90));
    const y = 60 + radius * Math.sin(toRadians(angle - 90));
    return `${x},${y}`;
  };

  // Función para generar path de arco
  const createArc = (startAngle, endAngle) => {
    const radius = 40;
    const start = getPointOnArc(startAngle);
    const end = getPointOnArc(endAngle);
    const largeArc = endAngle - startAngle <= 180 ? "0" : "1";
    return `M ${start} A ${radius},${radius} 0 ${largeArc} 1 ${end}`;
  };

  // Calculamos el ángulo para la aguja (270 grados)
  const getNeedleRotation = (value) => {
    const minAngle = -135; // Comenzamos desde -135 grados
    const maxAngle = 135; // Terminamos en 135 grados
    const minValue = 0; // Valor mínimo en la escala
    const maxValue = 10; // Valor máximo en la escala
    const angle =
      ((value - minValue) / (maxValue - minValue)) * (maxAngle - minAngle) +
      minAngle;
    return `rotate(${Math.min(Math.max(angle, minAngle), maxAngle)}deg)`;
  };

  const handleDeviceChange = (event) => {
    const newDevice = event.target.value;
    setSelectedDevice(newDevice);
    if (onDeviceChange) {
      onDeviceChange(newDevice);
    }
  };

  // Definimos los segmentos del velocímetro
  const segments = [
    { color: "#f56565", start: -135, end: -81 }, // Rojo
    { color: "#ecc94b", start: -81, end: -27 }, // Amarillo
    { color: "#48bb78", start: -27, end: 27 }, // Verde
    { color: "#ecc94b", start: 27, end: 81 }, // Amarillo
    { color: "#f56565", start: 81, end: 135 }, // Rojo
  ];

  return (
    <Card className="mx-auto w-64 p-4">
      <div className="flex flex-col items-center">
        <Select
          value={selectedDevice}
          onChange={handleDeviceChange}
          className="mb-4 w-full dark:bg-navy-700 dark:text-white"
        >
          {devices.map((device) => (
            <option key={device} value={device}>
              Sensor {device}
            </option>
          ))}
        </Select>

        {/* Gauge */}
        <div className="relative h-48 w-48">
          <div className="absolute h-full w-full">
            <svg className="absolute h-full w-full" viewBox="0 0 100 120">
              {/* Fondo gris */}
              <path
                d={createArc(-135, 135)}
                fill="none"
                strokeWidth="12"
                stroke="#e5e5e5"
                strokeLinecap="butt"
                className="dark:stroke-gray-700"
              />

              {/* Segmentos coloreados */}
              {segments.map((segment, index) => (
                <path
                  key={index}
                  d={createArc(segment.start, segment.end)}
                  fill="none"
                  strokeWidth="12"
                  stroke={segment.color}
                  strokeLinecap="butt"
                />
              ))}

              {/* Números */}
              <text
                x={getPointOnArc(-135, 52).split(",")[0]}
                y={getPointOnArc(-135, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
              >
                0
              </text>
              <text
                x={getPointOnArc(-108, 52).split(",")[0]}
                y={getPointOnArc(-108, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
              >
                1
              </text>
              <text
                x={getPointOnArc(-81, 52).split(",")[0]}
                y={getPointOnArc(-81, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
              >
                2
              </text>
              <text
                x={getPointOnArc(-54, 52).split(",")[0]}
                y={getPointOnArc(-54, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
              >
                3
              </text>
              <text
                x={getPointOnArc(-27, 52).split(",")[0]}
                y={getPointOnArc(-27, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
              >
                4
              </text>
              <text
                x={getPointOnArc(0, 52).split(",")[0]}
                y={getPointOnArc(0, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                5
              </text>
              <text
                x={getPointOnArc(27, 52).split(",")[0]}
                y={getPointOnArc(27, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="end"
              >
                6
              </text>
              <text
                x={getPointOnArc(54, 52).split(",")[0]}
                y={getPointOnArc(54, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="end"
              >
                7
              </text>
              <text
                x={getPointOnArc(81, 52).split(",")[0]}
                y={getPointOnArc(81, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="end"
              >
                8
              </text>
              <text
                x={getPointOnArc(108, 52).split(",")[0]}
                y={getPointOnArc(108, 50).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="end"
              >
                9
              </text>
              <text
                x={getPointOnArc(135, 52).split(",")[0]}
                y={getPointOnArc(135, 52).split(",")[1]}
                className="fill-current text-[8px] font-medium dark:fill-white"
                dominantBaseline="middle"
                textAnchor="end"
              >
                10
              </text>
            </svg>

            {/* Aguja */}
            <div
              className="bg-black absolute left-1/2 top-[60%] h-[40%] w-1 origin-bottom -translate-x-1/2 dark:bg-white"
              style={{ transform: getNeedleRotation(stats.current) }}
            >
              <div className="bg-black absolute -left-[2px] -top-[2px] h-2 w-2 rounded-full dark:bg-white" />
            </div>

            {/* Punto central */}
            <div className="bg-black absolute left-1/2 top-[60%] h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full dark:bg-white" />
          </div>

          {/* Valor actual */}
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 text-2xl font-bold dark:text-white">
            {stats.current.toFixed(2)}
          </div>
        </div>

        {/* Estadísticas */}
        <div className="mt-4 grid w-full grid-cols-3 gap-2 text-center text-sm">
          <div>
            <div className="text-gray-500 dark:text-gray-400">Min</div>
            <div className="font-bold dark:text-white">
              {stats.min === Infinity ? "Infinity" : stats.min.toFixed(2)}
            </div>
          </div>
          <div>
            <div className="text-gray-500 dark:text-gray-400">Promedio</div>
            <div className="font-bold dark:text-white">
              {isNaN(stats.avg) ? "NaN" : stats.avg.toFixed(2)}
            </div>
          </div>
          <div>
            <div className="text-gray-500 dark:text-gray-400">Max</div>
            <div className="font-bold dark:text-white">
              {stats.max === -Infinity ? "-Infinity" : stats.max.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default GaugeChart;
